module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-react-axe/gatsby-browser.js'),
      options: {"plugins":[],"showInProduction":false,"axeOptions":{}},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Pedro Pinho Portfolio","short_name":"Pedro Pinho Portfolio","description":"My portfolio, where I have my projects, skills and info. 🙂","start_url":"/","background_color":"#2d2d2d","theme_color":"#2d2d2d","display":"minimal-ui","theme_color_in_head":false,"icon":"src/images/icon.png","icons":[{"src":"icons/icon_512x819.png","sizes":"512x819","types":"image/png"},{"src":"icons/icon_120x192.png","sizes":"120x192","types":"image/png"}]},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../../../var/task/node_modules/@vercel/gatsby-plugin-vercel-analytics/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
